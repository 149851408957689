import React, { useState } from 'react';
import cmsUtils from '../../utils/cmsUtils';
import utils from '../../../utils';
import { Item } from '../../items/item';
import { SiteLink } from '../../../components';
import './style.scss'

export function ClubsTilesItem(props) {
    const item = props.item;
    const subItems = item.items || [];
    const cmsOption = props.cmsOption || {};

    const teamName = cmsUtils.payload(item, "TeamName");
    const addressLine1 = cmsUtils.payload(item, "AddressLine1");
    const suburb = cmsUtils.payload(item, "Suburb");
    const state = cmsUtils.payload(item, "State");
    const postcode = cmsUtils.payload(item, "Postcode");
    const websiteAddress = cmsUtils.payload(item, "WebsiteAddress");
    const heroImg = cmsUtils.payload(item, "ImageUrl");
    const homeUniformImg = cmsUtils.payload(item, "ImageUrl2");
    const guestUniformImg = cmsUtils.payload(item, "ImageUrl3");
    const googleMapLink = cmsUtils.payload(item, "LinkUrl");

    const ImageSwitcher = ({ homeUniformImg, guestUniformImg }) => {
        const [currentImg, setCurrentImg] = useState(homeUniformImg);

        return (
            <div
                className="tileContent__img__uniformImg"
                onMouseEnter={() => setCurrentImg(guestUniformImg)}
                onMouseLeave={() => setCurrentImg(homeUniformImg)}
            >
                <img src={currentImg} alt="Uniform" />
            </div>
        );
    };

    // console.log("Club Tiles Item:", item);
    // console.log("subItems:", subItems);

    // State to track the current image
    const [currentImg, setCurrentImg] = useState(homeUniformImg);

    const cssClass = utils.classNames('cms_item', 'clubsTilesItem', item.cssClass || item.anchorName || '')

    return (
        <section
            className={cssClass}
            onMouseEnter={() => setCurrentImg(guestUniformImg)}
            onMouseLeave={() => setCurrentImg(homeUniformImg)}
        >
            <div className='tileContent'>
                <div className='tileContent__img'>
                    <div className='tileContent__img__teamLogo'>
                        <img src={heroImg}></img>
                    </div>
                    <div className='tileContent__img__uniformImg'>
                        <img src={currentImg}></img>
                    </div>
                </div>
                <div className='tileContent__teamDetail'>
                    <div className='tileContent__teamDetail__teamName'>{teamName}</div>
                    <div className='tileContent__teamDetail__physicalAddress'>
                        <SiteLink to={googleMapLink}>
                            {`${addressLine1}, ${suburb} ${state} ${postcode}`}
                        </SiteLink>
                    </div>
                    <div className='tileContent__teamDetail__websiteLink'>
                        <a href={websiteAddress} target='_blank'>Visit website &gt;</a>
                    </div>
                </div>
            </div>
        </section>

    );
}