import React, { useState, useEffect } from "react";
import { SiteLink } from "../../components";
import { useStore } from "../../store";
import { FaPlus } from "react-icons/fa";
import { FaMinus } from "react-icons/fa";
import { BiSearchAlt } from "react-icons/bi";
import { SearchBar } from "./searchBar2";

export function NavBarHori(props) {
  const currentLocation = props.currentLocation;
  const { state } = useStore();
  const menu = state.menu || { items: [] };
  const [searchOpened, setSearchOpened] = useState(false);

  const depth = 1;
  const menuItemElts = menu.items.map((item, index) => (
    <React.Fragment key={index}>
      {/* {index > 0 && <li className='horizontal_menu_separator'>|</li>} */}
      <MenuItem
        item={item}
        depth={depth}
        onCloseMenu={props.onCloseMenu}
        currentLocation={currentLocation}
      />
    </React.Fragment>
  ));

  // if (searchOpened) {
  //   return (
  //     <div className='horizontal_menu'>
  //       <ul className='horizontal_menu_ul'>{menuItemElts} </ul>
  //       <div
  //         className={`search_container ${
  //           searchOpened ? "search_container_open" : ""
  //         }`}
  //         onClick={() => setSearchOpened(!searchOpened)}
  //       >
  //         <BiSearchAlt size={"25"} />
  //         <span>SEARCH</span>
  //       </div>
  //       <SearchBar
  //         onCloseMenu={() => setSearchOpened(false)}
  //         setSearchOpened={setSearchOpened}
  //       />
  //     </div>
  //   );
  // } else {
    return (
      <div className='horizontal_menu'>
        <ul className='horizontal_menu_ul'>{menuItemElts} </ul>
        <div className='search_container' onClick={() => setSearchOpened(true)}>
          <BiSearchAlt size={"25"} />
          <span>SEARCH</span>
        </div>
      </div>
    );
  // }
}

function MenuItem(props) {
  const [subMenuOpened, setSubMenuOpened] = useState(false);
  const item = props.item;
  const subItems = item.items || [];
  const currentLocation = props.currentLocation;

  const subItemsElts =
    subItems.length > 0
      ? subItems.map((subItem, index) => (
          <SubMenuItem
            item={subItem}
            key={index}
            onCloseMenu={props.onCloseMenu}
            level={0}
          />
        ))
      : null;

  useEffect(() => {
    setSubMenuOpened(false);
  }, [currentLocation]);

  function closeMenu() {
    props.onCloseMenu();
  }

  if (item.url) {
    return (
      <li
        className={`horizontal_menu_item ${
          subMenuOpened ? "horizontal_menu_item--open" : ""
        }`}
      >
        <div className='horizontal_menu_link'>
          <SiteLink to={item.url} onClick={() => closeMenu()}>
            {item.title}
          </SiteLink>
        </div>
        {subItemsElts && (
          <ul className={`horizontal_subMenu`}>{subItemsElts}</ul>
        )}
      </li>
    );
  } else {
    return (
      <li
        className={`horizontal_menu_item ${
          subMenuOpened ? "horizontal_menu_item--open" : ""
        }`}
      >
        <div className='horizontal_menu_link'>
          <span>{item.title}</span>
        </div>
        {subItemsElts && (
          <ul className={`horizontal_subMenu`}>{subItemsElts}</ul>
        )}
      </li>
    );
  }
}

function SubMenuItem(props) {
  const item = props.item;
  const level = props.level + 1;
  const childItem = item.items || [];
  const [subMenuOpened, setSubMenuOpened] = useState(false);
  const childElts = childItem.map((item, index) => {
    return (
      <SubMenuItem
        key={index}
        item={item}
        onCloseMenu={props.onCloseMenu}
        level={level}
      />
    );
  });

  function closeMenu() {
    props.onCloseMenu();
  }

  function toggleSubmenu(e) {
    e.preventDefault();
    e.stopPropagation();
    setSubMenuOpened(!subMenuOpened);
  }

  return (
    <li
      className={`level__${level} navBar__subItem ${level > 1 && "sub__level"}`}
    >
      <SiteLink
        className='navBar__subLink'
        to={item.url}
        onClick={() => closeMenu()}
      >
        <span>{item.title}</span>
        {childElts && childElts.length > 0 && (
          <span
            className={`navBar__icon__sub ${
              subMenuOpened ? "navBar__icon__sub--open" : ""
            }`}
            onClick={(e) => toggleSubmenu(e)}
          >
            {" "}
            {subMenuOpened ? <FaMinus /> : <FaPlus />}{" "}
          </span>
        )}
      </SiteLink>
      {childElts && childElts.length > 0 && (
        <ul
          className={`level__${level + 1} navBar__subMenu ${
            level + 1 > 1 && "sub__level"
          } ${level + 1 > 6 && "over__level"} ${
            subMenuOpened ? "navBar__subMenu--open" : ""
          }`}
        >
          {childElts}
        </ul>
      )}
    </li>
  );
}
