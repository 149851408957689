import React, { useState } from 'react';
import cmsUtils from '../../utils/cmsUtils';
import { Item } from '../../items/item';
import utils from '../../../utils';

export function ClubsTilesPanel(props) {
    const item = props.item;
    const subItems = item.items || [];
    const cmsOption = props.cmsOption || {};

    const cssClass = utils.classNames('cms_item', 'clubTilePanel' + item.cssClass || item.anchorName || '');

    const subItemsElts = subItems.map((subItem, index) =>
        <Item item={subItem} key={subItem.itemId} cmsOption={cmsOption}></Item>
    );

    const sortedTeams = subItemsElts.slice().sort((a, b) => {
        const teamA = a.props.item.payloads[3].value.toLowerCase();
        const teamB = b.props.item.payloads[3].value.toLowerCase();
        return teamA.localeCompare(teamB);
    })

    const buttonRanges = {
        ABC: ["A", "B", "C"],
        DEFG: ["D", "E", "F"],
        HIJKL: ["H", "I", "J", "K", "L"],
        MNO: ["M", "N", "O"],
        PQR: ["P", "Q", "R"],
        S: ["S"],
        TUVWXYZ: ["T", "U", "V", "W", "X", "Y", "Z"],
    };

    const [filteredTeams, setFilteredTeams] = useState(sortedTeams);

    const handleButtonClick = (range) => {
        const rangeLetters = buttonRanges[range];
        const filtered = sortedTeams.filter((team) =>
            rangeLetters.some((letter) => team.props.item.payloads[3].value[0].toUpperCase() === letter)
        );

        if (filtered.length === 0) {
            const firstMatch = sortedTeams.find((team) =>
                team.props.item.payloads[3].value[0].toUpperCase() > rangeLetters[rangeLetters.length - 1]
            );
            setFilteredTeams(firstMatch ? [firstMatch] : []);
        } else {
            setFilteredTeams(filtered);
        }

    };

    console.log("Club panel subitems:", subItemsElts);

    console.log("team name:", subItemsElts[0].props.item.payloads[3].value);

    return (
        <section className={cssClass}>
            {/*<div className='teams__group'>
                {Object.keys(buttonRanges).map((range) => (
                    <button key={range} onClick={() => handleButtonClick(range)}>
                        {range}
                    </button>
                ))}
            </div>*/}
            <div className='container-clubs-tiles'>
                {sortedTeams}
            </div>
        </section>
    );
}